import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { User } from 'firebase/auth';

export interface UserProfile {
  uid: string;
  displayName: string | null;
  email: string | null;
  bio?: string;
  socialLinks?: {
    x?: string;
    instagram?: string;
    website?: string;
  };
  paymentLinks?: {
    paypal?: string;
    revolut?: string;
    wise?: string;
  };
  walletAddresses?: {
    ethereum?: string;
    bitcoin?: string;
  };
  customStyles?: {
    primaryColor: string;
    backgroundColor: string;
  };
  tipPageEnabled: boolean;
  tipPageId: string;
  createdAt: string;
  updatedAt: string;
}

function generateTipPageId(): string {
  return Math.random().toString(36).substring(2, 15) + 
         Math.random().toString(36).substring(2, 15);
}

export async function createOrUpdateUserProfile(user: User): Promise<void> {
  if (!user.uid) throw new Error('User ID is required');

  const userRef = doc(db, 'users', user.uid);
  const retries = 3;
  
  for (let i = 0; i < retries; i++) {
    try {
      const userDoc = await getDoc(userRef);
      const now = new Date().toISOString();
      
      if (!userDoc.exists()) {
        // Create new profile
        const newProfile: UserProfile = {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          bio: '',
          socialLinks: {},
          paymentLinks: {},
          walletAddresses: {},
          customStyles: {
            primaryColor: '#059669',
            backgroundColor: '#f9fafb'
          },
          tipPageEnabled: true,
          tipPageId: generateTipPageId(),
          createdAt: now,
          updatedAt: now,
        };
        
        await setDoc(userRef, newProfile);
      } else {
        // Update existing profile
        await setDoc(userRef, {
          displayName: user.displayName,
          email: user.email,
          updatedAt: now,
        }, { merge: true });
      }
      break;
    } catch (error: any) {
      console.error('Error in createOrUpdateUserProfile:', error);
      if (i === retries - 1) {
        if (error.code === 'unavailable') {
          throw new Error('Network error. Please check your connection and try again.');
        }
        throw error;
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }
}

export async function getUserProfile(userId: string): Promise<UserProfile | null> {
  if (!userId) throw new Error('User ID is required');

  const retries = 3;
  
  for (let i = 0; i < retries; i++) {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        return null;
      }
      
      return userDoc.data() as UserProfile;
    } catch (error: any) {
      console.error('Error in getUserProfile:', error);
      if (i === retries - 1) {
        if (error.code === 'unavailable') {
          throw new Error('Network error. Please check your connection and try again.');
        }
        throw new Error('Failed to load user profile');
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }
  
  return null;
}

export async function updateUserProfile(userId: string, profileData: Partial<UserProfile>): Promise<void> {
  if (!userId) throw new Error('User ID is required');

  const userRef = doc(db, 'users', userId);
  const now = new Date().toISOString();

  try {
    await setDoc(userRef, {
      ...profileData,
      updatedAt: now
    }, { merge: true });
  } catch (error) {
    console.error('Error updating profile:', error);
    throw new Error('Failed to update profile');
  }
}