import React from 'react';
import { TreePine } from 'lucide-react';

interface AppErrorFallbackProps {
  error?: Error;
  resetErrorBoundary?: () => void;
}

export default function AppErrorFallback({ error, resetErrorBoundary }: AppErrorFallbackProps) {
  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-sm p-6 text-center">
        <TreePine className="h-12 w-12 text-emerald-600 mx-auto mb-4" />
        <h1 className="text-2xl font-bold text-gray-900 mb-2">Oops! Something went wrong</h1>
        <p className="text-gray-600 mb-4">
          {error?.message || 'An unexpected error occurred'}
        </p>
        {process.env.NODE_ENV === 'development' && error && (
          <pre className="mt-4 p-4 bg-gray-50 rounded text-left text-sm text-gray-700 overflow-auto">
            {error.stack}
          </pre>
        )}
        <button
          onClick={resetErrorBoundary}
          className="mt-4 bg-emerald-600 text-white px-6 py-2 rounded-lg hover:bg-emerald-700 transition-colors"
        >
          Try Again
        </button>
      </div>
    </div>
  );
}