import React from 'react';
import { Link } from 'react-router-dom';
import { Wallet, QrCode, BarChart, Shield } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

export default function Landing() {
  const { currentUser } = useAuth();
  
  return (
    <div className="bg-gradient-to-b from-emerald-50 to-white">
      {/* Hero Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16 text-center">
        <h1 className="text-4xl sm:text-5xl font-bold text-gray-900 mb-6">
          Simplify Your Digital Tipping Experience
        </h1>
        <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
          TipTree makes it easy for service workers, artists, and performers to receive and manage tips digitally.
        </p>
        <Link
          to={currentUser ? "/dashboard" : "/auth"}
          className="inline-block bg-emerald-600 text-white px-8 py-3 rounded-lg text-lg font-medium hover:bg-emerald-700 transition-colors"
        >
          {currentUser ? "Go to Dashboard" : "Start Accepting Tips"}
        </Link>
      </div>

      {/* Features Section */}
      <div id="features" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
          Why Choose TipTree?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <FeatureCard
            icon={<Wallet className="h-8 w-8 text-emerald-600" />}
            title="Instant Payments"
            description="Receive tips instantly with our secure payment processing system"
          />
          <FeatureCard
            icon={<QrCode className="h-8 w-8 text-emerald-600" />}
            title="Custom QR Codes"
            description="Generate unique QR codes for easy tipping access"
          />
          <FeatureCard
            icon={<BarChart className="h-8 w-8 text-emerald-600" />}
            title="Analytics Dashboard"
            description="Track your earnings with detailed analytics and insights"
          />
          <FeatureCard
            icon={<Shield className="h-8 w-8 text-emerald-600" />}
            title="Secure Platform"
            description="Bank-level security to protect your earnings and data"
          />
        </div>
      </div>

      {/* How It Works Section */}
      <div id="how-it-works" className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            How It Works
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <Step
              number="1"
              title="Create Your Account"
              description="Sign up in minutes and customize your tipping profile"
            />
            <Step
              number="2"
              title="Share Your Link"
              description="Share your unique tipping link or QR code with customers"
            />
            <Step
              number="3"
              title="Receive Tips"
              description="Get paid instantly when customers leave you a tip"
            />
          </div>
        </div>
      </div>

      {/* Demo Notice */}
      {!import.meta.env.VITE_FIREBASE_API_KEY && (
        <div className="bg-yellow-50 border-t border-yellow-200 py-3">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <p className="text-center text-yellow-700">
              Running in demo mode. Some features may be limited.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

function FeatureCard({ icon, title, description }: { icon: React.ReactNode; title: string; description: string }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm text-center">
      <div className="flex justify-center mb-4">{icon}</div>
      <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
}

function Step({ number, title, description }: { number: string; title: string; description: string }) {
  return (
    <div className="text-center">
      <div className="w-12 h-12 bg-emerald-600 text-white rounded-full flex items-center justify-center text-xl font-bold mx-auto mb-4">
        {number}
      </div>
      <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
}