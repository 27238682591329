import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu, X, TreePine } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

export default function Navbar() {
  const [isOpen, setIsOpen] = React.useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <TreePine className="h-8 w-8 text-emerald-600" />
              <span className="ml-2 text-xl font-bold text-gray-900">TipTree</span>
            </Link>
          </div>
          
          <div className="hidden sm:flex sm:items-center sm:space-x-8">
            {!currentUser ? (
              <>
                <Link to="#features" className="text-gray-600 hover:text-gray-900">Features</Link>
                <Link to="#how-it-works" className="text-gray-600 hover:text-gray-900">How it Works</Link>
                <Link
                  to="/auth"
                  className="bg-emerald-600 text-white px-4 py-2 rounded-lg hover:bg-emerald-700 transition-colors"
                >
                  Get Started
                </Link>
              </>
            ) : (
              <>
                <Link 
                  to="/dashboard" 
                  className={`text-gray-600 hover:text-gray-900 ${location.pathname === '/dashboard' ? 'text-emerald-600 font-medium' : ''}`}
                >
                  Dashboard
                </Link>
                <Link 
                  to={`/tip/${currentUser.uid}`} 
                  className={`text-gray-600 hover:text-gray-900 ${location.pathname === `/tip/${currentUser.uid}` ? 'text-emerald-600 font-medium' : ''}`}
                >
                  My Tip Page
                </Link>
                <button
                  onClick={handleLogout}
                  className="text-gray-600 hover:text-gray-900"
                >
                  Logout
                </button>
              </>
            )}
          </div>

          <div className="sm:hidden flex items-center">
            <button onClick={() => setIsOpen(!isOpen)} className="text-gray-600">
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="sm:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {!currentUser ? (
              <>
                <Link
                  to="#features"
                  className="block px-3 py-2 text-gray-600 hover:text-gray-900"
                >
                  Features
                </Link>
                <Link
                  to="#how-it-works"
                  className="block px-3 py-2 text-gray-600 hover:text-gray-900"
                >
                  How it Works
                </Link>
                <Link
                  to="/auth"
                  className="block w-full text-center mt-2 bg-emerald-600 text-white px-4 py-2 rounded-lg hover:bg-emerald-700 transition-colors"
                >
                  Get Started
                </Link>
              </>
            ) : (
              <>
                <Link
                  to="/dashboard"
                  className={`block px-3 py-2 text-gray-600 hover:text-gray-900 ${location.pathname === '/dashboard' ? 'text-emerald-600 font-medium' : ''}`}
                >
                  Dashboard
                </Link>
                <Link
                  to={`/tip/${currentUser.uid}`}
                  className={`block px-3 py-2 text-gray-600 hover:text-gray-900 ${location.pathname === `/tip/${currentUser.uid}` ? 'text-emerald-600 font-medium' : ''}`}
                >
                  My Tip Page
                </Link>
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-3 py-2 text-gray-600 hover:text-gray-900"
                >
                  Logout
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}