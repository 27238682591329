import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Save, Loader2 } from 'lucide-react';
import { updateUserProfile, getUserProfile } from '../services/userService';

interface ProfileData {
  displayName: string;
  bio: string;
  socialLinks: {
    x?: string;
    instagram?: string;
    website?: string;
  };
  paymentLinks: {
    paypal?: string;
    revolut?: string;
    wise?: string;
  };
  walletAddresses: {
    ethereum?: string;
    bitcoin?: string;
  };
  customStyles: {
    primaryColor: string;
    backgroundColor: string;
  };
}

export default function Profile() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [profileData, setProfileData] = useState<ProfileData>({
    displayName: '',
    bio: '',
    socialLinks: {},
    paymentLinks: {},
    walletAddresses: {},
    customStyles: {
      primaryColor: '#059669',
      backgroundColor: '#f9fafb'
    }
  });

  useEffect(() => {
    async function loadProfile() {
      if (!currentUser) return;
      
      try {
        const profile = await getUserProfile(currentUser.uid);
        if (profile) {
          setProfileData({
            displayName: profile.displayName || currentUser.displayName || '',
            bio: profile.bio || '',
            socialLinks: profile.socialLinks || {},
            paymentLinks: profile.paymentLinks || {},
            walletAddresses: profile.walletAddresses || {},
            customStyles: profile.customStyles || {
              primaryColor: '#059669',
              backgroundColor: '#f9fafb'
            }
          });
        }
      } catch (err) {
        console.error('Error loading profile:', err);
        setError('Failed to load profile data');
      } finally {
        setLoading(false);
      }
    }

    loadProfile();
  }, [currentUser]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser) return;

    setSaving(true);
    setError('');
    setSuccess('');

    try {
      await updateUserProfile(currentUser.uid, profileData);
      setSuccess('Profile updated successfully');
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Failed to update profile');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-emerald-600" />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">Profile Settings</h1>

      {error && (
        <div className="mb-4 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
          {error}
        </div>
      )}

      {success && (
        <div className="mb-4 bg-emerald-50 border border-emerald-200 text-emerald-600 px-4 py-3 rounded-lg">
          {success}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="space-y-4">
            <div>
              <label htmlFor="displayName" className="block text-sm font-medium text-gray-700">
                Display Name
              </label>
              <input
                type="text"
                id="displayName"
                value={profileData.displayName}
                onChange={(e) => setProfileData({ ...profileData, displayName: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
              />
            </div>

            <div>
              <label htmlFor="bio" className="block text-sm font-medium text-gray-700">
                Bio
              </label>
              <textarea
                id="bio"
                rows={4}
                value={profileData.bio}
                onChange={(e) => setProfileData({ ...profileData, bio: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                placeholder="Tell others about yourself..."
              />
            </div>

            <div className="space-y-4">
              <h3 className="text-sm font-medium text-gray-700">Social Links</h3>
              
              <div>
                <label htmlFor="x" className="block text-sm text-gray-500">
                  X (formerly Twitter)
                </label>
                <input
                  type="url"
                  id="x"
                  value={profileData.socialLinks.x || ''}
                  onChange={(e) => setProfileData({
                    ...profileData,
                    socialLinks: { ...profileData.socialLinks, x: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                  placeholder="https://x.com/username"
                />
              </div>

              <div>
                <label htmlFor="instagram" className="block text-sm text-gray-500">
                  Instagram
                </label>
                <input
                  type="url"
                  id="instagram"
                  value={profileData.socialLinks.instagram || ''}
                  onChange={(e) => setProfileData({
                    ...profileData,
                    socialLinks: { ...profileData.socialLinks, instagram: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                  placeholder="https://instagram.com/username"
                />
              </div>

              <div>
                <label htmlFor="website" className="block text-sm text-gray-500">
                  Website
                </label>
                <input
                  type="url"
                  id="website"
                  value={profileData.socialLinks.website || ''}
                  onChange={(e) => setProfileData({
                    ...profileData,
                    socialLinks: { ...profileData.socialLinks, website: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                  placeholder="https://yourwebsite.com"
                />
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="text-sm font-medium text-gray-700">Payment Links</h3>
              
              <div>
                <label htmlFor="paypal" className="block text-sm text-gray-500">
                  PayPal
                </label>
                <input
                  type="url"
                  id="paypal"
                  value={profileData.paymentLinks.paypal || ''}
                  onChange={(e) => setProfileData({
                    ...profileData,
                    paymentLinks: { ...profileData.paymentLinks, paypal: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                  placeholder="https://paypal.me/username"
                />
              </div>

              <div>
                <label htmlFor="revolut" className="block text-sm text-gray-500">
                  Revolut
                </label>
                <input
                  type="url"
                  id="revolut"
                  value={profileData.paymentLinks.revolut || ''}
                  onChange={(e) => setProfileData({
                    ...profileData,
                    paymentLinks: { ...profileData.paymentLinks, revolut: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                  placeholder="https://revolut.me/username"
                />
              </div>

              <div>
                <label htmlFor="wise" className="block text-sm text-gray-500">
                  Wise
                </label>
                <input
                  type="url"
                  id="wise"
                  value={profileData.paymentLinks.wise || ''}
                  onChange={(e) => setProfileData({
                    ...profileData,
                    paymentLinks: { ...profileData.paymentLinks, wise: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                  placeholder="https://wise.com/pay/me/username"
                />
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="text-sm font-medium text-gray-700">Crypto Wallets</h3>
              
              <div>
                <label htmlFor="ethereum" className="block text-sm text-gray-500">
                  Ethereum Address
                </label>
                <input
                  type="text"
                  id="ethereum"
                  value={profileData.walletAddresses.ethereum || ''}
                  onChange={(e) => setProfileData({
                    ...profileData,
                    walletAddresses: { ...profileData.walletAddresses, ethereum: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                  placeholder="0x..."
                />
              </div>

              <div>
                <label htmlFor="bitcoin" className="block text-sm text-gray-500">
                  Bitcoin Address
                </label>
                <input
                  type="text"
                  id="bitcoin"
                  value={profileData.walletAddresses.bitcoin || ''}
                  onChange={(e) => setProfileData({
                    ...profileData,
                    walletAddresses: { ...profileData.walletAddresses, bitcoin: e.target.value }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                  placeholder="bc1..."
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Theme Colors
              </label>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="primaryColor" className="block text-sm text-gray-500">
                    Primary Color
                  </label>
                  <input
                    type="color"
                    id="primaryColor"
                    value={profileData.customStyles.primaryColor}
                    onChange={(e) => setProfileData({
                      ...profileData,
                      customStyles: {
                        ...profileData.customStyles,
                        primaryColor: e.target.value
                      }
                    })}
                    className="mt-1 block w-full h-10 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="backgroundColor" className="block text-sm text-gray-500">
                    Background Color
                  </label>
                  <input
                    type="color"
                    id="backgroundColor"
                    value={profileData.customStyles.backgroundColor}
                    onChange={(e) => setProfileData({
                      ...profileData,
                      customStyles: {
                        ...profileData.customStyles,
                        backgroundColor: e.target.value
                      }
                    })}
                    className="mt-1 block w-full h-10 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={saving}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 disabled:opacity-50"
          >
            {saving ? (
              <>
                <Loader2 className="animate-spin h-4 w-4 mr-2" />
                Saving...
              </>
            ) : (
              <>
                <Save className="h-4 w-4 mr-2" />
                Save Changes
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}