import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Building2, ExternalLink, CheckCircle2, AlertCircle } from 'lucide-react';
import { useLocation } from 'react-router-dom';

interface StripeAccountStatus {
  chargesEnabled: boolean;
  payoutsEnabled: boolean;
  detailsSubmitted: boolean;
}

export default function StripeConnect() {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [accountStatus, setAccountStatus] = useState<StripeAccountStatus | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const setup = params.get('setup');
    
    if (setup === 'complete') {
      checkAccountStatus();
    } else if (setup === 'refresh') {
      setError('Please complete your Stripe account setup');
    }
  }, [location]);

  const checkAccountStatus = async () => {
    if (!currentUser) return;

    try {
      const response = await fetch(`/.netlify/functions/check-account-status?userId=${currentUser.uid}`);
      const contentType = response.headers.get('content-type');
      
      if (!contentType?.includes('application/json')) {
        throw new Error('Invalid response format from server');
      }

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to check account status');
      }
      
      setAccountStatus(data);
    } catch (err) {
      console.error('Error checking account status:', err instanceof Error ? err.message : 'Unknown error');
      setError('Unable to verify account status. Please try again.');
    }
  };

  useEffect(() => {
    if (currentUser) {
      checkAccountStatus();
    }
  }, [currentUser]);

  const handleConnect = async () => {
    if (!currentUser) return;
    
    setLoading(true);
    setError('');
    
    try {
      const returnUrl = `${window.location.origin}/dashboard`;
      const response = await fetch(
        `/.netlify/functions/create-connect-account?userId=${currentUser.uid}&returnUrl=${encodeURIComponent(returnUrl)}`
      );

      const contentType = response.headers.get('content-type');
      if (!contentType?.includes('application/json')) {
        throw new Error('Invalid response format from server');
      }

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to create Stripe account');
      }

      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error('No redirect URL received');
      }
    } catch (err) {
      console.error('Error:', err instanceof Error ? err.message : 'Unknown error');
      setError('Failed to connect with Stripe. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (accountStatus?.chargesEnabled && accountStatus?.payoutsEnabled) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <CheckCircle2 className="h-8 w-8 text-green-500 mr-3" />
            <div>
              <h3 className="text-lg font-medium text-gray-900">
                Connected to Stripe
              </h3>
              <p className="text-sm text-gray-500">
                Your account is ready to accept payments
              </p>
            </div>
          </div>
          <a
            href="https://dashboard.stripe.com"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <ExternalLink className="h-4 w-4 mr-2" />
            Stripe Dashboard
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="text-center">
        <Building2 className="h-12 w-12 text-gray-400 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          Connect with Stripe
        </h3>
        <p className="text-gray-500 mb-6">
          Accept credit card payments directly to your bank account
        </p>

        {error && (
          <div className="mb-4 flex items-center justify-center text-red-600 bg-red-50 p-3 rounded-lg">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}

        <button
          onClick={handleConnect}
          disabled={loading}
          className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 disabled:opacity-50"
        >
          {loading ? (
            <>
              <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
              Connecting...
            </>
          ) : (
            <>
              <ExternalLink className="h-5 w-5 mr-2" />
              Connect with Stripe
            </>
          )}
        </button>
      </div>
    </div>
  );
}